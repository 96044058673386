<div class="spinner-container" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
<div class="container2">
    <div class="widget-toolbox" fxLayout="row" fxLayoutAlign="end center">
        <button mat-button (click)="onReload()"
            [disabled]="isLoading"
            matTooltip="reload the data"
            matTooltipClass="tooltip1">
            <mat-icon>autorenew</mat-icon>
        </button>
    </div>

    <div class="table-containter">
    <table class="mat-table">
        <caption>&nbsp;Xcel Building Status: Item <strong>{{offset * 20 + 1}}</strong> to <strong>{{offset * 20 + 20}}</strong></caption>
        <thead class="mat-header-row">
            <tr class="mat-row">
                <th class="mat-header-cell shorter">No.</th>
                <th class="mat-header-cell regular">Client</th>
                <th class="mat-header-cell regular">Company</th>
                <th class="mat-header-cell long">Template</th>
                <th class="mat-header-cell short">Type<mat-icon class="help-info" (click)="openHelpInfo($event, 16)">info</mat-icon></th>
                <th class="mat-header-cell short">Start</th>
                <th class="mat-header-cell center short">Status<mat-icon class="help-info" (click)="openHelpInfo($event, 15)">info</mat-icon></th>
                <th class="mat-header-cell short">Complete</th>
                <th class="mat-header-cell short">Csskey</th>
                <th class="mat-header-cell shorter">Juri</th>
                <th class="mat-header-cell shorter">Svr</th>

            </tr>
        </thead>
        <tbody>
            <tr class="mat-row filter">
                <td class="mat-cell">
                    <input type="checkbox" id="filter" name="filter"
                    (click) = "onFilterCheck()" [disabled] = "filterCheckboxDisabled" [checked]="hasFilter">
                </td>
                <td class="mat-cell">
                    <input type="text" id="clientName" name="fname"
                    (input)="onClientInputEvent($event.target.value)"
                    (keyup.backspace)="onSearchInputBackspace('clientName')"
                    (keyup.enter)="onSearchClient()">
                </td>
                <td class="mat-cell">
                    <input type="text" id="companyName" name="fname"
                    (input)="onCompanyInputEvent($event.target.value)"
                    (keyup.backspace)="onSearchInputBackspace('companyName')"
                    (keyup.enter)="onSearchCompany()">
                </td>
                <td class="mat-cell">
                    <input type="text" id="auditName" name="fname"
                    (input)="onAuditInputEvent($event.target.value)"
                    (keyup.backspace)="onSearchInputBackspace('auditName')"
                    (keyup.enter)="onSearchAudit()">
                <td class="mat-cell">
                    <select name="buildType" id="bt" (change)="onTypeChange($event.target.value)" [value]="buildType">
                        <option value="" selected disabled hidden>Choose</option>
                        <option value="X3.0">X4.0</option>
                        <option value="X3.1">X4.1</option>
                        <option value="C3.0">C3.0</option>
                        <option value="csv">csv</option>
                        <option value="reghub">reghub</option>
                        <option value="upgrading">upgrading</option>
                        <option value="velocity">velocity</option>
                        <option value="processmap">Ideagen EHS</option>
                        <option value="intelex">intelex</option>
                        <option value="ETQ">ETQ</option>
                        <option value="SafetyStr">SafetyStr</option>
                    </select>
                </td>
                <td class="mat-cell"></td>
                <td class="mat-cell">
                    <select name="buildingStatus" id="buildingStatus" (change)="onStatusChange($event.target.value)" [value]="buildingStatus">
                        <option value="" selected disabled hidden>Choose</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </td>
                <td class="mat-cell"></td>
                <td class="mat-cell">
                    <input type="text" id="csskey" class="short"
                    (input)="onCsskeyInputEvent($event.target.value)"
                    (keyup.backspace)="onSearchInputBackspace('csskey')"
                    (keyup.enter)="onSearchCsskey()">
                </td>
                <td class="mat-cell"></td>
                <td class="mat-cell"></td>
            </tr>
            <tr  *ngFor="let item of temData; let i = index"
                class="mat-row" [ngClass] = "{'warn': item.buildStatus > 2, 'inprocess': item.buildStatus < 2}">
                <td class="mat-cell">{{offset * 20 + i + 1}}</td>
                <td class="mat-cell">{{item.clientName}}</td>
                <td class="mat-cell">{{item.company}}</td>
                <td class="mat-cell">{{item.auditName}}</td>
                <td class="mat-cell">{{item.buildType.replace("X3", "X4")}}</td>
                <td class="mat-cell">{{item.reqDate.toLocaleString().slice(5, 16).replace("T", " ")}}</td>
                <td class="mat-cell center">{{item.buildStatus}}</td>
                <td class="mat-cell">{{item.completeDate?.toLocaleString().slice(5, 16).replace("T", " ")}}
                    <button *ngIf="item.buildStatus == 1||item.buildStatus==3" matTooltip="rebuild"
                    matTooltipClass="tooltip1" (click) = "rebuild(item.id)">
                    <mat-icon>restore</mat-icon>
                </button>
                </td>
                <td class="mat-cell">{{item.csskey}}</td>
                <td class="mat-cell">{{item.state}}</td>
                <td class="mat-cell">{{item.builder}}</td>

            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td colspan="11">
                    <button mat-button
                    class="button-action" *ngIf = "offset > 0" (click)="loadNext(false)">Previous</button>
                    <button mat-button [disabled]="temData.length < 20"
                    class="button-action float-right" (click)="loadNext(true)">Next</button>
                </td>
            </tr>
        </tfoot>
    </table>
    </div>
</div>