<div class="spinner-container" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
<div class="container2" (click)="collapseSearch($event)">
    <div class="table-containter">
    <table class="mat-table">
        <caption class="pubview">&nbsp;From:&nbsp;
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1 color="primary"></mat-datepicker>
            <input (dateInput)="OnStartDateChange($event.value)" class="dateInput"
              [disabled]="this.isLoading"
                matInput [matDatepicker]="picker1"
                placeholder="Date" [(ngModel)]="startDate">&nbsp;&nbsp;&nbsp;To:&nbsp;
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 color="primary"></mat-datepicker>
            <input (dateInput)="OnToDateChange($event.value)" class="dateInput"
                matInput [matDatepicker]="picker2"
                placeholder="Date" [(ngModel)]="toDate">
        </caption>
        <thead class="mat-header-row">
            <tr class="mat-row">
                <th class="mat-header-cell short">No.</th>
                <th class="mat-header-cell long">Product</th>
                <th class="mat-header-cell short">Views</th>
                <th class="mat-header-cell regular">Format</th>
                <th class="mat-header-cell long">Company</th>
                <th class="mat-header-cell long">User</th>
            </tr>
        </thead>
        <tbody>
            <tr class="mat-row filter">
                <td class="mat-cell shorter">
                    <input type="checkbox" id="filter" name="filter"
                    (click) = "onFilterCheck()" [disabled] = "filterCheckboxDisabled" [checked]="hasFilter">
                </td>
                <td class="mat-cell regular">
                    <input type="text" id="acronym" name="fname"
                    (input)="onProductInputEvent($event.target.value)"
                    (keyup.backspace)="onSearchInputBackspace()"
                    (keyup.enter)="onSearchProduct()">
                </td>
                <td class="mat-cell short"></td>
                <td class="mat-cell long">
                    <select name="formatType" id="bt" (change)="onTypeChange($event.target.value)" [value]="formatType">
                        <option value="1" selected>Choose</option>
                        <option value="doc">doc</option>
                        <option value="pdf">pdf</option>
                        <option value="xls">xls</option>
                    </select>
                </td>
                <td class="mat-cell regular">
                    <div class="dropdown">
                        <button mat-button
                            class="button-action"
                            (click)="showSearchCompany =! showSearchCompany" >
                            {{loadCompany}}&nbsp;<mat-icon>search</mat-icon>
                        </button>
                        <div id="myDropdown" class="dropdown-content" [ngClass]="showSearchCompany? 'show' : 'hide'">
                          <input type="text"
                            placeholder="Search.."
                            [(ngModel)]="searchWord"
                            id="company"
                            (input)="onSearchCompany($event.target.value)"
                            (keyup.enter) = "onSearchCompanyEnter()">

                            <a *ngFor="let c of companyList" class="firstlayer"
                            (click)="loadForCompany(c);$event.stopPropagation()">{{c}}</a>
                        </div>
                      </div>
                </td>
                <td class="mat-cell regular">

                </td>
            </tr>
            <tr  *ngFor="let item of temData; let i = index"
                class="mat-row" >
                <td class="mat-cell shorter">{{pageStart + i + 1}}</td>
                <td class="mat-cell regular clickable" (click) = "loadAcronym(item.acronym)">{{item.acronym}}</td>
                <td class="mat-cell short">{{item.hits}}</td>
                <td class="mat-cell long">{{item.format}}</td>
                <td class="mat-cell regular ">{{item.company}}</td>
                <td class="mat-cell regular ">{{item.user}}</td>
            </tr>
        </tbody>
        <tfoot>
            <tr>

                    <td class="mat-cell shorter"><button mat-button
                        class="button-action" *ngIf = "offset > 0" (click)="loadNext(false)">Previous</button></td>
                    <td class="mat-cell regular"></td>
                    <td class="mat-cell short"></td>
                    <td class="mat-cell long"><button mat-button
                        class="button-action"></button></td>
                    <td class="mat-cell regular "></td>
                    <td class="mat-cell regular "> <button mat-button [disabled]="cacheData.length < 20"
                        class="button-action float-right" (click)="loadNext(true)">Next</button></td>
            </tr>
        </tfoot>
    </table>
    </div>
</div>