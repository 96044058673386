import { Component, OnInit, Inject } from '@angular/core';
import {FormBuilder, Validators, FormGroup, FormControl} from '@angular/forms';
import { AlertifyService } from '../../_models/alertify.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';

import { CustomScoring,CustomRanking, CustomTemplate} from '../model/CustomQuestion';
import { CustomQuestionService } from '../service/custom-question.service';

@Component({
  selector: 'app-rank-and-score',
  templateUrl: './rank-and-score.component.html',
  styleUrls: ['./rank-and-score.component.css']
})
export class RankAndScoreComponent implements OnInit {

  customTemplateList: CustomTemplate[]; // All the custom template for this client.
  selectedTemplateKey: number; // The current custom template key.
  selectedTemplateName: string;
  selectedUserId: number; // the user id who owns the current custom template.
  customScoring: CustomScoring[];
  customRanking: CustomRanking[];

  constructor(
    public service: CustomQuestionService,
    private alertifyService: AlertifyService,
    public dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit() {
    this.service.setTopicChangeFlag(false);

    // Call backend to get the custom Template for this client.
    this.service.getCustomTemplate().subscribe(
      res => {
        //console.log("backend return: " + res);
        this.customTemplateList = res;
        this.selectedTemplateKey = this.getActiveCustomTemplateKey(); // By default, display the active custom template.
        this.selectedTemplateName = this.getTemplateName(this.selectedTemplateKey);

        this.service.getCustomScoring(this.selectedTemplateKey).subscribe(
          data => {
            this.customScoring = data;
            let item =  this.customTemplateList.find(ct => ct.templateKey === this.selectedTemplateKey);
            this.selectedUserId = item.userId;
          }
        )
      }
    );

  }

  getActiveCustomTemplateKey(): number {
    for(var tp of this.customTemplateList){
      if(tp.activeStatus && tp.templateKey !== 0){
        return tp.templateKey;
      }
    }
    return 0;
  }

  backToHome(){
    this.router.navigateByUrl("/audithub/apptool/list");
  }

  // Event handler: click on button NEW Custom Template.
  onNewTemplate(){
    const dialogRef = this.dialog.open(NewTemplateDialog, {
      position: {top: '200px'},
      width: '480px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.service.createCustomTemplate(result).subscribe(
          rep => {
            this.alertifyService.success("New template saved.");
            let account = localStorage.getItem("account");
            let usr = account === null ? 0 : JSON.parse(account).id;

            var nc: CustomTemplate = {
              templateKey: rep,
              templateName: result.templateName,
              shareStatus: result.shareStatus,
              activeStatus: result.activeStatus,
              userId: usr
            };
            if(nc.activeStatus){
              this.updateTemplateActiveStatus(rep);
            }
            this.customTemplateList.push(nc);
            this.selectedTemplateKey = rep;
            this.selectedTemplateName = result.templateName;
            this.customScoring = [];
            this.customRanking = [];
          }
        );
      }
    });
  }

  onEditTemplate(){
    if(this.selectedTemplateKey  == 0){
      this.alertifyService.warning("Cannot edit Default template.");
      return false;
    }
    let item =  this.customTemplateList.find(ct => ct.templateKey === this.selectedTemplateKey);

    const dialogRef = this.dialog.open(EditTemplateDialog, {
      position: {top: '200px'},
      width: '480px',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        result.templateKey = this.selectedTemplateKey;
        this.service.saveCustomTemplate(result).subscribe(
          rep => {
            console.log("after close dialog backend return: " + rep);
            this.alertifyService.success("Template saved.");
            if (item) {
              item.templateName = result.templateName;            
              item.shareStatus = result.shareStatus;            
              item.activeStatus = result.activeStatus;
              if(item.activeStatus){
                this.updateTemplateActiveStatus(this.selectedTemplateKey);
              }
            }
            this.selectedTemplateName = result.templateName;
          }
        );
      }
    });
  }

  // Set the active status of the template to false.
  updateTemplateActiveStatus(templateId: number){
    let items = this.customTemplateList.filter(ct => ct.templateKey !== templateId);
    if (items.length > 0) {
      items.forEach(element => {
        element.activeStatus = false;
      });
    }
  }

  // Event handler: click on button Delete Custom Template.
  onDeleteTemplate(){
    if(this.selectedTemplateKey  == 0){
      this.alertifyService.error("Cannot delete Default template.");
      return false;
    }

    let item =  this.customTemplateList.find(ct => ct.templateKey === this.selectedTemplateKey);
    let usr = JSON.parse(localStorage.getItem("account")).id;
    if(item.userId != usr){
      this.alertifyService.confirmOk("Cannot delete a shared custom template.");
      return false;
    }

    this.alertifyService.confirmOkCancel("STP AuditHub", "Are you sure to delete the custom template?",
    () =>{
      this.service.deleteCustomTemplate(+this.selectedTemplateKey).subscribe(
        res => {
          this.alertifyService.success("Custom template deleted.");
          var index = 0;
          for(let element of this.customTemplateList){
            if(element.templateKey == this.selectedTemplateKey){
              this.customTemplateList.splice(index, 1);
              this.onClickTemplate(0);
            }
            index++;
          }
        },
        error => {
          this.alertifyService.error(error.message);
        }
      );

    },
    () =>{// do nothing
    });
  }

  // An template is clicked, call backend to get the content.
  onClickTemplate(templateId: number){
    this.selectedTemplateKey = templateId;
    this.selectedTemplateName = this.getTemplateName(templateId);
    let item =  this.customTemplateList.find(ct => ct.templateKey === this.selectedTemplateKey);
    this.selectedUserId = item.userId;

    if(this.service.getOnScoring()){
      this.service.getCustomScoring(templateId).subscribe(
        data => {
          this.customScoring = data;
        }
      )
    }else{
      this.service.getCustomRanking(this.selectedTemplateKey).subscribe(
        data => {
          this.customRanking = data;
        },
        error =>{
          console.log("backend return error: " + error.status);
          if(error.status == 404){
            this.customRanking = null;
          }
        }
      )
    }

  }

  // To load the scoring for the current template. true: load Scoring; false: load Ranking.
  onLoadContents(ind: boolean){
    console.log("parent get request: to load the current contents:" + ind);
    this.service.setOnScoring(ind);
    if(ind){
      this.service.getCustomScoring(this.selectedTemplateKey).subscribe(
        data => {
          this.customScoring = data;
        }
      )
    }else{
      this.service.getCustomRanking(this.selectedTemplateKey).subscribe(
        data => {
          this.customRanking = data;
        },
        error =>{
          console.log("backend return error: " );
        }
      )

    }
  }

  // Given a templateId, return its template name.
  getTemplateName(templateId: number): string {
    for(var tm of this.customTemplateList){
      if(tm.templateKey == templateId){
        return tm.templateName;
      }
    }
    return null;
  }

  // to add a new scoring item.
  onAddScoringItem(data: CustomScoring){
    console.log("parent get request: add new scoring item: " + data.ScoringName);
    data.ScoringKey = this.selectedTemplateKey;

    this.service.addScoringElement(data).subscribe(
      rep => {
        console.log("backend return: " + rep);
        data.ScoringKey = rep;
        if(this.customScoring == null){
          this.customScoring = [data];
        }else{
          this.customScoring.push(data);
        }
      },
      error =>{
        console.log("backend return error: " + error.message);
        this.alertifyService.error(error.statusText);
      }
    )
  }

  // To add a new ranking item.
  onAddRankingItem(data: CustomRanking){
    console.log("parent get request: add new ranking item:" + data.RankingName);
    data.RankingKey = this.selectedTemplateKey;
    this.service.addRankingElement(data).subscribe(
      rep => {
        console.log("backend return: " + rep);
        data.RankingKey = rep;
        if(this.customRanking == null){
          this.customRanking = [data];
        }else{
          this.customRanking.push(data);
        }

      }
    )
  }
}

@Component({
  selector: 'new-template',
  templateUrl: './new-template.html',
  styleUrls: ['./rank-and-score.component.css']
})
export class NewTemplateDialog implements OnInit {
  myGroup: FormGroup;
  templateControl: FormControl;
  activeCheckbox: false;
  shareCheckbox: false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<NewTemplateDialog>) {
      this.myGroup = new FormGroup({
        templateControl: new FormControl('', Validators.required)
      });
    }

  ngOnInit(){
    this.activeCheckbox = false;
    this.shareCheckbox = false;
  }

  close(): void {
    this.dialogRef.close();
  }

  // To save the new custom topic.
  onNewTemplate(){
    let data: CustomTemplate =  {
      templateName: this.myGroup.value.templateControl,
      templateKey: 0,
      shareStatus : this.shareCheckbox,
      activeStatus : this.activeCheckbox,
      userId: 0
    };
    this.dialogRef.close(data);
  }

}


@Component({
  selector: 'edit-template',
  templateUrl: './edit-template.html',
  styleUrls: ['./rank-and-score.component.css']
})
export class EditTemplateDialog {
  myGroup: FormGroup;
  templateControl: FormControl;
  activeCheckbox: boolean;
  shareCheckbox: boolean;
  userId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CustomTemplate,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<NewTemplateDialog>) {
      this.myGroup = new FormGroup({
        templateControl: new FormControl(data.templateName, Validators.required)
      });
      this.activeCheckbox = data.activeStatus;
      this.shareCheckbox = data.shareStatus;
      this.userId = JSON.parse(localStorage.getItem("account")).id; // the current user id.
      if(this.userId != data.userId){
        this.myGroup.disable();
      }
    }


  close(): void {
    this.dialogRef.close();
  }

  // To save the custom topic.
  onSaveTemplate(){
    let data: CustomTemplate =  {
      templateName: this.myGroup.value.templateControl,
      templateKey: 0,
      shareStatus : this.shareCheckbox,
      activeStatus : this.activeCheckbox,
      userId: 0
    };
    this.dialogRef.close(data);
  }
}